<!--
 * @Descripttion: 续费
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-08 16:10:34
-->
<template>
	<div>
		<van-nav-bar title="充值中心" left-arrow @click-left="$router.push('/mine')" />
		<div class="black">
			<div class="goldBg temp">
				<div class="rightTopDiv" :class="{'hasPermission': hasPermission}"></div>
				<div class="pad flex-normal">
					<img :src="orgPath" class="w15">
					<div class="mar-l2">
						<h3>{{org.departName}}</h3>
						<p class="f35" v-if="org.serviceStatus=='01'||org.serviceStatus=='03'">{{org.expireDate}}到期</p>
						<p class="f35" v-if="org.serviceStatus==null||org.serviceStatus=='00'||org.serviceStatus=='02'">暂未开通专家库抽取服务</p>
					</div>
				</div>
			</div>
		</div>
		<div class="pad-row">
			<h3 class="pad-col">增值实用服务</h3>
			<ul class="renew">
				<li v-for="(item) in list" :key="item.id" @click="onSelected(item)" :class="selectedItem.id ==item.id ? 'active': ''">
					<h3 style="#1E1E1E">{{ item.serviceName }}</h3>
					<p>
						<template v-if="showDiscountPrice"><span class="f35">￥</span><span>{{ item.currentPrice}}</span></template>
						<template v-else><span class="f35">￥</span><span>{{ item.originalPrice}}</span></template>

						<del class="gray2" v-if="showDiscountPrice">￥{{ item.originalPrice }}</del>

					</p>
					<p class="red f35">{{ item.serviceDesc }}</p>
				</li>
			</ul>

			<h3 class="mar-t">服务介绍</h3>
			<p class="mar-t gray">专家记具备完善的专家管理、详尽的专业区分、快捷的抽取过程、灵活的补抽机制等特点，支持多专业评标专家同时抽取。模块间相互独立，区别管理员与操作员的权限，操作简便、保密性强。</p>
		</div>
		<div class="h2"></div>
		<div class="fixed-bottom pad25">
			<button class="btn goldBg w-100" @click="onRecharge()" v-show="showOnPay">立即充值</button>
			<button class="btn goldBg w-100" @click="onApply()" v-show="showOnApply">立即申请</button>
		</div>
		<van-dialog v-model="dialogVisible" title="充值服务-请微信扫码联系客服人员" :show-confirm-button="showConfirmButton" :show-cancel-button="showCancelButton"
			cancel-button-text="关闭">
			<div align="center">
				<img width="200" height="200" align="middle" src="https://hiweke.bidsoon.cn/sys/common/static/icon/icon_kefu01.png" />
			</div>		
		</van-dialog>		
	</div>
</template>

<script>
	import global from "@/common/global"
	export default {
		data() {
			return {
				services: [],
				selectIndex: '',
				selectId: '',
				queryParam: {
					orgId: "",
				},
				pageParams: {
					pageNo: 1,
					pageSize: 20
				},
				org: {
					surplusDays: 0,
				},
				list: [],
				selectedItem: {
					id: '',
					originalPrice: 0, //原价
					currentPrice: 0, //现价
					rechargePrice: 0, //充值价格--最终价格
				}, //选中的服务
				payObj: {

				},
				showDiscountPrice: false,
				rechargePrice: 0,
				promoterId: '', //推广员主键
				hasPermission: false, // 是否已开通权限
				orgPath: '',
				showOnPay: true, //立即充值
				showOnApply: false, //立即申请
				workweixin: false,				//企业微信
				openUserid:	"",
				dialogVisible: false,
				showConfirmButton: false,
				showCancelButton: true				
			}
		},
		created() {
			//企微微信标志
			this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
			if(this.workweixin){
				this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
			}
			//
			if (this.$ls.get('edCurrentOrgExternal')) {
				this.queryParam.orgId = this.$ls.get('edCurrentOrgExternal').id;
				this.org = this.$ls.get('edCurrentOrgExternal');
			} else if (this.$ls.get("edCurrentOrgExternal") && this.$ls.get("edCurrentOrg")) {
				this.queryParam.orgId = this.$ls.get('edCurrentOrgExternal').id;
				this.org = this.$ls.get('edCurrentOrgExternal');
				this.org.departName = this.$ls.get("edCurrentOrg").departName;
			}
			/** 获取机构Logon */
			this.orgPath = this.$ls.get("edCurrentOrgExternal").orgPic ? this.$ls.get("edCurrentOrgExternal").orgPic :
				'/static/img/icon/default-logo.png'

			/** 判断是否已开通服务 */
			if (this.$ls.get("edCurrentOrgExternal").serviceStatus &&
				(this.$ls.get("edCurrentOrgExternal").serviceStatus == '01' || this.$ls.get("edCurrentOrgExternal").serviceStatus ==
					'03')) {
				this.hasPermission = true
			}
			this.getAddedService();
			this.promoterId = this.$ls.get('edCurrentUser').userExternal.promoterId;
			if (this.promoterId != null && this.promoterId != '' && this.promoterId != undefined) {
				this.showDiscountPrice = true;
			} else {
				//当前没有推广的用户价格 也是360元，2021-05-06
				this.showDiscountPrice = true;
			}

		},
		methods: {
			//专家库增值服务表
			getAddedService() {
				var params = Object.assign({}, this.queryParam)
				params.pageNo = this.pageParams.pageNo;
				params.pageSize = this.pageParams.pageSize;
				params.openid = this.workweixin?this.openUserid:this.$ls.get("openid")
				params.orgId = this.org.id;
				let accesstoken = this.$ls.get("token")
				this.$ajax({
					methods: 'get',
					url: '/gateway/ed/wx/wxEdValueAddedService/list/',
					headers: {
						'X-Access-Token': this.$ls.get("token")
					},
					params: params,
				}).then(res => {
					if (res.data.success) {
						this.list = res.data.result;
					} else {
						this.$toast.fail("失败")
						console.log(res.data.message)
					}
				});
			},
			//充值成功
			onRecharge() {
				if (this.showDiscountPrice) {
					this.selectedItem.rechargePrice = this.selectedItem.currentPrice;
				} else if (this.showDiscountPrice == false) {
					this.selectedItem.rechargePrice = this.selectedItem.originalPrice;
				}
				if (this.selectedItem.id == '') {
					this.$toast.fail('请选择充值服务')
					return;
				}
				if (this.selectedItem.rechargePrice <= 0) {
					this.$toast.fail('服务价格不能小于等于0')
					return;
				}
				if (this.selectedItem.rechargePrice == '') {
					this.$toast.fail('服务价格不能为空')
					return;
				}
				//企业微信服务
				if(this.workweixin){
					this.dialogVisible = true;
				}else{
					//开通服务商品
					this.doUnifiedOrder();
				}

			},
			//选中服务
			onSelected(item) {
				this.selectedItem = item;
				if (item.productCategory == '1') {
					//1正式产品
					this.showOnPay = true;
					this.showOnApply = false;
				} else if (item.productCategory == '2') {
					//2试用产品
					this.showOnPay = false;
					this.showOnApply = true;
				} else {
					//1正式产品
					this.showOnPay = true;
					this.showOnApply = false;
				}

			},
			//充值成功，开通服务
			onOpenService() {
				let data = {};
				data.orgId = this.queryParam.orgId;
				data.vasId = this.selectedItem.id;
				data.promoterId = this.promoterId; //推广员主键
				data.rechargeAmount = this.selectedItem.rechargePrice; //首次充值金额
				this.$ajax({
					method: "post",
					url: "/gateway/ed/wx/wxEdValueAddedService/addAddedService",
					headers: {
						"X-Access-Token": this.$ls.get("token"),
					},
					params: {
						openid: (this.workweixin?this.openUserid:this.$ls.get("openid"))
					},
					data: data
				}).then((res) => {
					if (res.data.success) {
						this.$toast.success("服务开通成功");
						this.$router.push({
							name: 'mine'
						});
					} else {
						this.$toast.fail(res.data.message)
					}
				});
			},
			//服务商品
			doUnifiedOrder() {
				if (this.selectedItem.rechargedTimes >= this.selectedItem.exchangeTimes) {
					this.$toast.fail("当前已累计充值" + this.selectedItem.rechargedTimes + "次，等服务到期后再进行充值！")
					return;
				}
				let dataObj = {
					body: this.selectedItem.serviceName,
					openid: this.workweixin?this.openUserid:this.$ls.get("openid"),
					totalFee: this.selectedItem.rechargePrice,
					appId: global.appid
				}
				this.$ajax
					.post("/gateway/integral/wxPay/doUnifiedOrder", dataObj)
					.then((res) => {
						if (res.data.success) {
							this.payObj = res.data.result;
							this.onBridgeReady();
						} else {
							alert(res.data.message);
							this.$toast.fail(res.data.message)
						}
					})
			},
			//调用JSAPI顾客微信支付
			onBridgeReady() {
				let _this = this;
				WeixinJSBridge.invoke(
					'getBrandWCPayRequest', {
						"appId": global.appid, //公众号名称，由商户传入
						"timeStamp": _this.payObj.timeStamp, //时间戳，自1970年以来的秒数
						"nonceStr": _this.payObj.nonceStr, //随机串
						"package": _this.payObj.package, //统一下单接口返回的prepay_id参数值
						"signType": _this.payObj.signType, //微信签名方式：
						"paySign": _this.payObj.paySign //微信签名
					},
					function(res) {
						if (res.err_msg == "get_brand_wcpay_request:ok") {
							// 使用以上方式判断前端返回,微信团队郑重提示：
							//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
							// alert("谢谢您的赞赏，嗨微客平台将为您提供更优质的服务！");
							//充值成功，开通服务
							_this.onOpenService();
						}
					});
			},
			//申请试用服务
			onApply() {
				let data = {};
				data.orgId = this.queryParam.orgId;
				data.vasId = this.selectedItem.id;
				data.promoterId = this.promoterId; //推广员主键
				data.rechargeAmount = this.selectedItem.currentPrice; //充值金额
				this.$ajax({
					method: "post",
					url: "/gateway/ed/wx/wxEdValueAddedService/addAddedService",
					headers: {
						"X-Access-Token": this.$ls.get("token"),
					},
					params: {
						openid: (this.workweixin?this.openUserid:this.$ls.get("openid"))
					},
					data: data
				}).then((res) => {
					if (res.data.success) {
						this.$toast.success("申请成功");
						this.$router.push({
							name: 'mine'
						});
					} else {
						this.$toast.fail(res.data.message)
					}
				});
			},
			//下一个方法
		},
	}
</script>

<style scoped>
	.btn {
		color: #1E1E1E;
		font-weight: bold;
	}

	.black {
		background-color: #26252B;
		padding: 0.5rem 0.3rem 0;
	}

	.temp {
		border-radius: 0.2rem 0.2rem 0 0;
		position: relative;
	}

	.rightTopDiv {
		position: absolute;
		overflow: hidden;
		background: linear-gradient(to right bottom, #9A6E24, #6B4300);
		border-radius: 0 0.2rem;
		right: 0;
		top: 0;
		width: 2rem;
		height: 1.5rem;
		clip-path: polygon(0 0, 100% 100%, 100% 0);
	}

	.rightTopDiv::before {
		content: '未开通';
		display: inline-block;
		color: white;
		font-size: 0.3rem;
		transform: rotate(42deg);
		width: 2rem;
		margin-top: 0.8rem;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		justify-content: center;
		transform-origin: right bottom;
	}

	.hasPermission::before {
		content: '生效中';
	}

	.renew {
		width: 100%;
		display: flex;
		overflow: scroll;
	}

	.renew>li {
		flex-shrink: 0;
		width: 45%;
		border: 1px solid #E6E6E6;
		text-align: center;
		border-radius: 0.2rem;
		padding: 0.2rem;
		margin-left: 2%;
	}

	.renew>li:first-child {
		margin-left: 0;
	}

	.renew>li>p>span:nth-child(2) {
		font-size: 0.55rem;
		font-weight: bold;
	}

	.renew>li>p>del {
		font-size: 0.35rem;
		display: inline-block;
		margin-left: 0.15rem;
	}

	.renew>li.active {
		border: 1px solid #E5B96F;
		color: #E5B96F;
		background: #FBF6E8;
	}

	.mar-l2 {
		width: calc(100% - 2.4rem);
	}

	.w15 {
		width: 1.5rem;
		height: 1.5rem;
	}
</style>
